@import 'core/variables.scss';
.login{
    background-image:url("../images/woodexpert.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#login {
    padding: 20vh 0 0;
    #nav, #backtoblog{
        text-align: center;
    }
    #nav a, #backtoblog a, .privacy-policy-link {
        transition: .25s all;
        color: $black;
        &:hover {
            color: $primary-color;
        }
    }
    .forgetmenot {
        padding-top: 10px;
    }
}
#login h1 a {
    background-image:url("../images/logo-white.png");
    background-size: 135px;
    width: 100%;
    }
    .wp-core-ui .button.button-large{
        height: auto;
        padding: 0.4rem 1.6rem;
        line-height: 26px;
    }
    .wp-core-ui .button-primary {
    background: $black;
    position: relative;
    border-radius: 0;
    padding: 0.6rem 2.2rem;
    font-size: .9rem;
    color: $white; 
    font-family: $button-font;
    border: 2px solid transparent;
    transition: .25s all;
    

      &:hover {
        cursor: pointer;
        color: $white;
        background: $gray;
        border-color: $gray;
        text-shadow: none;
        border-bottom: none;
        box-shadow: none;
        line-height: 26px;

      }
      

}
